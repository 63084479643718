import axios from "axios";
import { getToken, logUserOut } from "../utils/helper";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

// use interceptor to add jwt bearer token all api requests made
axios.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    ["Authorization"]: `Bearer ${getToken()}`,
  };

  return config;
});

// use interceptor to log user out if response status is 401
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) logUserOut("JWT_EXPIRED");
    return Promise.reject(error);
  }
);

const http = axios;

export default http;
