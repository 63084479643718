import React, { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { authenticateUser } from "../../services/authService";
import { setToken, setUser } from "../../utils/helper";
import Button from "../common/button";
import Input from "../common/input";
import PasswordShowHideInput from "../common/passwordShowHideInput";
import Joi from "joi";

const loginFormSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .messages({
      "string.empty": "Please enter your email address",
      "string.email": "Please enter a valid email address!",
    }),
  password: Joi.string().required().min(2).messages({
    "string.empty": "Please enter your password!",
    "string.min": "Please can't be 1 character long!",
  }),
});

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (window.history.state.type === "JWT_EXPIRED") {
      setTimeout(() => toast.error("Please login to continue!"), 150);
    }
  }, [location]);

  const validateForm = () => {
    const { error } = loginFormSchema.validate(
      { email, password },
      { abortEarly: false }
    );

    if (!error) return true;

    const formError = error.details.reduce(
      (err, currentError) => ({
        ...err,
        [currentError.context.key]: currentError.message,
      }),
      {}
    );

    setFormError(formError);
    return false;
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const { user, token, status } =
      (await authenticateUser(email, password)) ?? {};
    if (user) {
      setUser(user);
      setToken(token);
      navigate("/dashboard");
    } else if (status === 400) {
      toast.error("Invalid email or password!");
    }
  };

  return (
    <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-4 col-lg-5">
            <div className="card">
              <div className="card-header pt-4 pb-4 text-center bg-primary">
                <h4 className="text-light">Attendance Report</h4>
              </div>

              <div className="card-body p-4">
                <div className="text-center w-75 m-auto">
                  <h4 className="text-dark-50 text-center mt-0 fw-bold">
                    Sign In
                  </h4>
                  <p className="text-muted mb-4">
                    Enter your email address and password to access admin panel.
                  </p>
                </div>

                <form className={`${formError && "was-validated"}`}>
                  <Input
                    type="email"
                    placeholder="Enter your email"
                    label="Email address"
                    id="emailaddress"
                    value={email}
                    onChange={setEmail}
                    error={formError?.email}
                  />

                  <PasswordShowHideInput
                    id="password"
                    label="Password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={setPassword}
                    error={formError?.password}
                  />

                  <div className="mb-3 mb-0 text-center" onClick={handleLogin}>
                    <Button type="submit" className="btn-outline-primary">
                      Log in
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
