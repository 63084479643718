import React from "react";

const MemberProfilePageSkeleton = () => (
  <div className="container-fluid mb-5">
    <div className="row mt-4">
      <div className="col-xl-3 col-lg-4">
        <div
          class="card text-center skeleton"
          style={{ height: "18rem", borderRadius: "15px" }}
        >
          <div class="card-body"></div>
        </div>

        <div
          class="card text-center skeleton"
          style={{ height: "9rem", borderRadius: "15px" }}
        >
          <div class="card-body"></div>
        </div>

        <div
          class="card text-center skeleton mb-0"
          style={{ height: "9rem", borderRadius: "15px" }}
        >
          <div class="card-body"></div>
        </div>
      </div>
      <div className="col-xl-9 col-lg-8">
        <div
          class="card text-center skeleton h-100"
          style={{ height: "36rem", borderRadius: "15px" }}
        >
          <div class="card-body"></div>
        </div>
      </div>
    </div>
  </div>
);

export default MemberProfilePageSkeleton;
