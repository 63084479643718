import React from "react";
import Button from "./button";

const DeleteLogOverlay = ({ onCancel, onDelete }) => (
  <div className="w-50 table-row-overlay-outer">
    <div className="w-50 table-row-overlay p-2 ps-3 pe-3 d-flex align-items-center justify-content-between">
      <h6 className="text-center">
        <i className="uil uil-comment-exclamation me-1"></i>
        Delete this log?
      </h6>

      <div>
        <Button
          className="text-white btn-secondary badge p-1 ps-2 pe-2 br-15 me-2"
          onClick={onCancel}
        >
          No
        </Button>
        <Button
          className="text-white btn-danger badge p-1 ps-2 pe-2 br-15"
          onClick={onDelete}
        >
          Yes
        </Button>
      </div>
    </div>
  </div>
);

export default DeleteLogOverlay;
