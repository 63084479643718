import React from "react";

const Pagination = ({ totalItems, currentPage, pageSize, onPageChange }) => {
  if (totalItems <= pageSize) return null;

  const pages = [];
  const lastPage = Math.ceil(totalItems / pageSize);

  const start =
    currentPage === lastPage
      ? Math.max(currentPage - 2, 1)
      : currentPage > 1
      ? currentPage - 1
      : currentPage;

  for (let i = 0; i < 3; i++) {
    if (start + i <= lastPage) pages.push(start + i);
  }

  return (
    <nav>
      <ul class="pagination pagination-rounded mb-0">
        <li
          class={`page-item ${currentPage === 1 && "disabled"}`}
          onClick={() => onPageChange(1)}
        >
          <button class="page-link" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {pages.map((p, idx) => (
          <li
            key={idx}
            className={`page-item ${p === currentPage && "active"}`}
            onClick={() => onPageChange(p)}
          >
            <button class="page-link">{p}</button>
          </li>
        ))}
        <li
          class={`page-item ${currentPage === lastPage && "disabled"}`}
          onClick={() => onPageChange(lastPage)}
        >
          <button class="page-link" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
