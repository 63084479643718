import React, { useEffect, useState } from "react";

const inputType = {
  TEXT: "text",
  PASSWORD: "password",
};

const PasswordShowHideInput = ({
  id,
  value = "",
  label = "",
  placeholder = "",
  disabled = false,
  className = "",
  onChange = () => {},
  forgotPasswordText = "Forgot your password?",
  forgotPasswordLink = "",
  error = "",
}) => {
  const [type, setType] = useState(inputType.PASSWORD);
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (passwordVisible) setType(inputType.TEXT);
    else setType(inputType.PASSWORD);
  }, [passwordVisible]);

  return (
    <div className={`mb-3 ${className}`}>
      <a href={forgotPasswordLink} className="text-muted float-end">
        <small>{forgotPasswordText}</small>
      </a>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <div className="input-group input-group-merge">
        <input
          type={type}
          id={id}
          className={`form-control ${error && "invalid"}`}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
        />
        <div
          className={`input-group-text ${passwordVisible && "show-password"}`}
          data-password="false"
          onClick={() => setPasswordVisible(!passwordVisible)}
        >
          <span className="password-eye"></span>
        </div>
        <div className="invalid-feedback">{error}</div>
      </div>
    </div>
  );
};

export default PasswordShowHideInput;
