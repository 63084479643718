import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Pagination from "../components/common/pagination";
import Footer from "../components/footer";
import MainNavBar from "../components/mainNavBar";
import ChangeStatusForm from "../components/members/changeStatusForm";
import CreateAdminForm from "../components/members/createAdminForm";
import MemberCard from "../components/members/MemberCard";
import MemberPageSkeleton from "../components/skeletons/memberPageSkeleton";
import { memberFilters } from "../config";
import { deleteMember, getAllMembers } from "../services/memberService";
import { scrollToTop } from "../utils/helper";

const Members = () => {
  const [allMembers, setAllMembers] = useState([]);
  const [members, setMembers] = useState([]);
  const [pageSize, setPageSize] = useState(32);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [showCreateAdminForm, setShowCreateAdminForm] = useState(false);
  const [showChangeStatusForm, setShowChangeStatusForm] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(memberFilters[0]);
  const [searchText, setSearchText] = useState("");

  const firstRowSerial = Math.min(total, (currentPage - 1) * pageSize + 1);
  const lastRowSerial = Math.min(total, pageSize * currentPage);

  const currentPageMembers = () => {
    return members.filter(
      (m, idx) => idx + 1 >= firstRowSerial && idx + 1 <= lastRowSerial
    );
  };

  const loadMembers = async () => {
    const members = (await getAllMembers()) ?? [];
    setAllMembers(members);
    setMembers(members);
    setTotal(members?.length);
    setLoading(false);
  };

  useEffect(() => {
    loadMembers();
  }, []);

  useEffect(() => {
    let filteredMembers = allMembers.filter((m) => {
      let matchesName = `${m.memberName} ${m.memberDisplayName}`
        .toLowerCase()
        .includes(searchText.toLowerCase().trim());
      let matchesFilter = filter === "admin" ? m.isDashboardAdmin : true;
      return matchesName && matchesFilter;
    });

    setMembers(filteredMembers);
  }, [searchText, filter]);

  useEffect(() => {
    setCurrentPage(1);
    setTotal(members.length);
  }, [members]);

  useEffect(() => {
    scrollToTop();
  }, [currentPage]);

  const openCreateAdminForm = (member) => {
    setSelectedMember(member);
    setShowCreateAdminForm(true);
  };

  const openChangeStatusForm = (member) => {
    setSelectedMember(member);
    setShowChangeStatusForm(true);
  };

  const handleMemberDelete = async (member) => {
    const allMembers = members;

    toast.success(`${member.memberName} deleted!`);
    setMembers(allMembers.filter((m) => m.memberId !== member.memberId));

    const status = await deleteMember(member.memberId);
    if (status !== 200) {
      toast.dismiss();
      toast.error("Sorry something went wrong!");
      setMembers(allMembers);
    }
  };

  const renderMembers = () => {
    const list = currentPageMembers();

    return (
      <div className="row mt-3">
        {list.length < 1 && (
          <div className="text-center h-100">No member found!</div>
        )}
        {list.map((m) => (
          <div key={m.memberId} className="col-md-3 mb-4">
            <MemberCard
              member={m}
              onMakeAdminClick={openCreateAdminForm}
              onChangeStatusClick={openChangeStatusForm}
              onDelete={handleMemberDelete}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderPageContent = () => (
    <>
      <div className="container-fluid mt-2 pt-4 pb-4">
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ gap: "2.25rem" }}
        >
          <input
            type="text"
            class="form-control"
            id="memberSearch"
            placeholder="Search member..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          ></input>
          <div className="w-25 d-flex align-items-center">
            <label for="member-filter" class="form-label me-2 mb-0">
              Type
            </label>
            <select
              className="form-select"
              id="member-filter"
              value={filter.value}
              onChange={(e) => setFilter(e.target.value)}
            >
              {memberFilters.map((f) => (
                <option value={f.value}>{f.label}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-2">{renderMembers()}</div>
      <div className="container-fluid pt-4 pb-4 mb-5">
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ gap: "2.25rem" }}
        >
          <Pagination
            totalItems={total}
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
          />
        </div>
      </div>
    </>
  );

  return (
    <>
      {loading && <MemberPageSkeleton />}
      {!loading && renderPageContent()}
      {showCreateAdminForm && (
        <CreateAdminForm
          member={selectedMember}
          onClose={() => setShowCreateAdminForm(false)}
        />
      )}
      {showChangeStatusForm && (
        <ChangeStatusForm
          member={selectedMember}
          onClose={() => setShowChangeStatusForm(false)}
        />
      )}
    </>
  );
};

export default Members;
