import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { dateTimeFormats, formatDate } from "../../utils/helper";
import MemberContext from "../../contexts/MemberContext";
import { getListOfLateCheckInByDate } from "../../services/statisticsService";
import moment from "moment";
import toast from "react-hot-toast";

const MissingCheckoutCard = ({ icon, value, info }) => {
  const { membersById } = useContext(MemberContext);

  const [lateCheckInsCount, setLateCheckInsCount] = useState(0);
  const [lateCheckInLogs, setLateCheckInLogs] = useState(null);

  const copyToClipboard = async () => {
    const prefix = `Late as of 10:00am ${moment().format(dateTimeFormats.monthNameDateYear)}: `;
    const names = lateCheckInLogs.map((l) => membersById[l.id]?.displayName || membersById[l.id]?.name).join(", ");

    await navigator.clipboard.writeText(prefix + names);
    toast.success("Copied to clipboard!");
  };

  const loadListOfLateCheckIns = async () => {
    const { count, logs } = await getListOfLateCheckInByDate();
    setLateCheckInsCount(count);
    setLateCheckInLogs(logs);
  };

  useEffect(() => {
    loadListOfLateCheckIns();
  }, []);

  return (
    <div
      // class={`card h-100 mb-0 ${info ?? "skeleton"}`}
      class={`card ${lateCheckInLogs ?? "skeleton"}`}
      style={{ borderRadius: "0.5rem" }}
    >
      {
        <div class="card-body pt-3 pb-3 c-pointer">
          <div class="d-flex align-items-center ps-1 pe-1">
            <div class="flex-grow-1">
              <h6 class="mb-2 text-uppercase p-relative">
                Late check-ins
                {lateCheckInsCount ? (
                  <span className="card-hover-prompt" onClick={copyToClipboard}>
                    Click to copy
                    <i className="ms-1 uil uil-arrow-circle-right text-info"></i>
                  </span>
                ) : null}
              </h6>

              <h4 class={`mt-0 mb-1 font-20 ${lateCheckInsCount ? "text-danger" : "text-success"}`}>
                {lateCheckInsCount ? String(lateCheckInsCount).padStart(2, "0") : 0}
                <span class="ms-1 mb-0 text-muted fs-6">
                  {/* {workDate && `on ${formatDate(workDate, dateTimeFormats.dayNameMonthNameDate)}`} */}
                </span>
              </h4>
            </div>
            <div class="flex-shrink-0">{icon}</div>
          </div>

          {lateCheckInsCount ? (
            <div class="d-flex align-items-center ps-1 pe-1 mt-2">
              {/* <div class="flex-grow-1">
                <p class="mt-0 mb-0 font-20 text-danger">
                  <span class="mb-0 text-muted fs-6 fw-1">
                    Total <u className="">{total ? String(Number(total).toLocaleString()).padStart(2, "0") : total}</u>{" "}
                    missing checkouts
                  </span>
                </p>
              </div> */}
              <div className="flex-shirnk-0 multi-user text-end">
                {lateCheckInLogs?.map(({ id }) => (
                  <span key={id} class="d-inline-block" title={membersById[id]?.displayName || membersById[id]?.name}>
                    <img src={membersById[id]?.images?.image32} class="rounded-circle avatar-xs" alt="friend" />
                  </span>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      }
    </div>
  );
};

MissingCheckoutCard.defaultProps = {
  title: "Missing Checkouts",
  icon: <i className="uil uil-confused widget-icon bg-warning-lighten text-warning"></i>,
  info: null,
};

export default MissingCheckoutCard;
