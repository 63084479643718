import React from "react";
import { useEffect } from "react";
import { downloadLogs, getLogHistory } from "../services/attendanceService";
import { MultiSelect } from "react-multi-select-component";
import { useState } from "react";
import { getAllMembers } from "../services/memberService";
import { DateRangePicker, DefinedRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import { dateTimeFormats, scrollToTop } from "../utils/helper";
import Button from "../components/common/button";
import Pagination from "../components/common/pagination";
import HistoryLogRow from "../components/history/HistoryLogRow";

const History = () => {
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [members, setMembers] = useState([]);

  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize] = useState(50);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);

  const handleLogDownload = async () => {
    setDownloading(true);
    const dateRange = state[0];
    await downloadLogs(
      moment(dateRange.startDate).format(dateTimeFormats.yearMonthDate),
      moment(dateRange.endDate).format(dateTimeFormats.yearMonthDate),
      selectedMembers?.map((m) => m.memberId).join(",")
    );
    setDownloading(false);
  };

  const loadAllMembers = async () => {
    const members = (await getAllMembers()) ?? [];
    setMembers(
      members.map((m) => ({
        value: m.memberId,
        label: m.memberDisplayName || m.memberName,
        ...m,
      }))
    );
  };

  const loadLogHistory = async () => {
    setLoading(true);
    const dateRange = state[0];

    const { logs = [], pagination } = await getLogHistory({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      memberIds: selectedMembers?.map((m) => m.memberId).join(","),
      page,
      pageSize,
    });

    scrollToTop();
    setLogs(logs);
    setTotalPages(pagination.totalPages || 0);
    setTotalItems(pagination.totalItems || 0);
    setLoading(false);
  };

  useEffect(() => {
    loadAllMembers();
    loadLogHistory();
  }, []);

  useEffect(() => {
    loadLogHistory();
  }, [page]);

  const applyFilter = () => {
    setShowDatePicker(false);
    setPage(1);
    loadLogHistory();
  };

  const getSelectedDateRangeValue = () => {
    let range = state[0];
    let startDate = moment(range.startDate)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0);
    let endDate = moment(range.endDate)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0);

    let today = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
    let startOfWeek = moment()
      .startOf("week")
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0);
    let endOfWeek = moment()
      .endOf("week")
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0);
    let startOfMonth = moment()
      .startOf("month")
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0);
    let endOfMonth = moment()
      .endOf("month")
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0);

    if (today.diff(startDate, "d") === 0 && today.diff(endDate, "d") === 0) {
      return "Today";
    } else if (
      today.diff(startDate, "d") === 1 &&
      today.diff(endDate, "d") === 1
    ) {
      return "Yesterday";
    } else if (
      startDate.diff(startOfWeek, "d") === 0 &&
      endDate.diff(endOfWeek, "d") === 0
    ) {
      return "This Week";
    } else if (
      startOfWeek.diff(startDate, "week", true) === 1 &&
      endOfWeek.diff(endDate, "week", true) === 1
    ) {
      return "Last Week";
    } else if (
      startOfMonth.diff(startDate, "d") === 0 &&
      endOfMonth.diff(endDate, "d") === 0
    ) {
      return "This Month";
    } else if (
      startOfMonth.diff(startDate, "month", true) === 1 &&
      moment(startDate).endOf("month").diff(endDate, "d") === 0
    ) {
      return "Last Month";
    } else if (startDate.diff(endDate) === 0) {
      return moment(startDate).format(dateTimeFormats.monthNameDateYear);
    }

    return `${moment(range.startDate).format(
      dateTimeFormats.monthNameDateYear
    )} to ${moment(range.endDate).format(dateTimeFormats.monthNameDateYear)}`;
  };

  const toggleDatePicker = (e) => {
    e.stopPropagation();
    setShowDatePicker(!showDatePicker);
  };

  const firstRowSerial = Math.min(totalItems, (page - 1) * pageSize + 1);
  const lastRowSerial = Math.min(totalItems, pageSize * page);

  const handleLogUpdate = (log, logIdx) => {
    const newLogs = logs?.map((l, idx) =>
      l.id == log.id || idx == logIdx ? log : l
    );
    setLogs(newLogs);
  };

  const handleLogDelete = (log) => {
    const remainingLogs = logs?.map((l) => {
      if (l.id === log.id)
        return {
          ...log,
          id: null,
          isAbsent: 1,
          checkIn: null,
          checkout: null,
          workingHour: null,
        };
      else return l;
    });
    setLogs(remainingLogs);
  };

  const getCurrentPageInfo = () => {
    return `Showing ${firstRowSerial} to ${lastRowSerial} of ${totalItems} logs`;
  };

  const renderFilterSection = () => (
    <div className="container-fluid mt-3 mb-4">
      <div className="row justify-content-end">
        <div className="d-flex flex-row justify-content-end">
          <div className="me-2 w-25" style={{ position: "relative" }}>
            <div class="input-group h-100" onClick={toggleDatePicker}>
              <span class="input-group-text bg-primary border-primary text-white">
                <i class="mdi mdi-calendar-range font-13"></i>
              </span>
              <input
                type="text"
                class="form-control form-control-white"
                id="dash-daterange"
                value={getSelectedDateRangeValue()}
                readOnly
              />
            </div>
            <div
              style={{
                boxShadow: "0 0 35px 0 rgb(154 161 171 / 15%)",
                top: "50px",
                left: 0,
                position: "absolute",
                display: `${showDatePicker ? "block" : "none"}`,
                zIndex: "99",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <DateRangePicker
                onChange={(item) => setState([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={state}
                direction="vertical"
              />
            </div>
          </div>

          <div style={{ zIndex: "99" }} className="w-25">
            <MultiSelect
              options={members}
              value={selectedMembers}
              onChange={setSelectedMembers}
              labelledBy="Select"
              className=""
              hasSelectAll={false}
              filterOptions={(options, filter) => {
                if (!filter) return options;

                const re = new RegExp(filter, "i");
                return options.filter(
                  ({ memberName, memberDisplayName }) =>
                    (memberName || memberDisplayName) &&
                    (memberName.match(re) || memberDisplayName.match(re))
                );
              }}
              valueRenderer={(selected) =>
                (selected.length &&
                  selected?.map(({ memberImgUrl }) => (
                    <img
                      className="rounded-circle"
                      height={25}
                      src={memberImgUrl}
                    />
                  ))) ||
                "Search by members..."
              }
              ItemRenderer={({ checked, option, onClick, disabled }) => (
                <div
                  className={`item-renderer d-flex align-items-center ${
                    disabled ? "disabled" : ""
                  }`}
                >
                  <input
                    type="checkbox"
                    onChange={onClick}
                    checked={checked}
                    tabIndex={-1}
                    disabled={disabled}
                  />
                  <span className="ms-2 d-flex align-items-center">
                    <img
                      className="rounded-circle me-2"
                      width={33}
                      src={option.memberImgUrl}
                    />
                    {option.label}
                  </span>
                </div>
              )}
            />
          </div>

          <button className="btn btn-primary ms-2" onClick={applyFilter}>
            {/* <i className="mdi mdi-autorenew"></i> */} Filter
          </button>
        </div>
      </div>
    </div>
  );

  const renderPagination = () => (
    <div className="d-flex justify-content-between align-items-center mt-3">
      <span className="text-secondary">
        <b>{getCurrentPageInfo()}</b>
      </span>

      <Pagination
        totalItems={totalItems}
        currentPage={page}
        pageSize={pageSize}
        onPageChange={setPage}
      />
    </div>
  );

  const renderTableTitle = () => (
    <div className="d-flex justify-content-between align-items-center mb-2">
      <h4 className="header-title mb-0">Attendance History</h4>
      <Button
        className="text-info text-capitalize badge bg-info-lighten fs-6"
        onClick={handleLogDownload}
        disabled={downloading}
      >
        download{" "}
        {downloading ? (
          <div class="spinner-border spinner-border-sm" role="status"></div>
        ) : (
          <i className="mdi mdi-download"></i>
        )}
      </Button>
    </div>
  );

  const renderTableHeaders = () => (
    <thead>
      <tr>
        <th>Date</th>
        <th>Name</th>
        <th>Check In</th>
        <th>Check Out</th>
        <th>Hours Logged</th>
        <th></th>
      </tr>
    </thead>
  );

  const renderTableRows = () => (
    <tbody>
      {logs?.map((l, idx) => (
        <HistoryLogRow
          log={l}
          key={`${l.id}-${idx}`}
          idx={idx}
          onUpdate={handleLogUpdate}
          onDelete={handleLogDelete}
        />
      ))}
    </tbody>
  );

  const renderTable = () => (
    <div className="table-responsive">
      <table className="table table-centered table-nowrap mb-0">
        {renderTableHeaders()}
        {renderTableRows()}
      </table>
    </div>
  );

  const renderLogHistory = () => (
    <div className="container-fluid mb-5">
      <div className={`card ${loading && "skeleton"}`}>
        <div className="card-body">
          {renderTableTitle()}
          {renderTable()}
          {renderPagination()}
        </div>
      </div>
    </div>
  );

  return (
    <div onClick={(e) => setShowDatePicker(false)}>
      {renderFilterSection()}
      {renderLogHistory()}
    </div>
  );
};

export default History;
