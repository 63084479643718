import React from "react";

const Input = ({
  id,
  value = "",
  label = "",
  type = "text",
  placeholder = "",
  disabled = false,
  className = "",
  onChange = () => {},
  error = "",
}) => {
  return (
    <div className={`mb-3 ${className}`}>
      <label for={id} class="form-label">
        {label}
      </label>
      <input
        className={`form-control ${error && "invalid"}`}
        type={type}
        id={id}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
      />
      <div className="invalid-feedback">{error}</div>
    </div>
  );
};
export default Input;
