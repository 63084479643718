import React from "react";
import { useState } from "react";
import { getAllMembersWithDetails } from "../services/memberService";
import MemberContext from "./MemberContext";

const GlobalContextWrapper = ({ children }) => {
  const [members, setMembers] = useState({});

  const loadAllMembers = async () => {
    const members = await getAllMembersWithDetails();
    setMembers(members);
  };

  useState(() => {
    loadAllMembers();
  }, []);

  return (
    <MemberContext.Provider value={{ membersById: members }}>
      {children}
    </MemberContext.Provider>
  );
};

export default GlobalContextWrapper;
