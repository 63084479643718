import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/footer";
import MainNavBar from "../components/mainNavBar";
import MemberAttendanceTable from "../components/memberProfile.jsx/memberAttendanceTable";
import MemberProfilePageSkeleton from "../components/skeletons/memberProfilePageSkeleton";
import {
  getMemberAttendanceLogs,
  getMemberAverageWorkingTime,
} from "../services/attendanceService";
import { getMember } from "../services/memberService";
import {
  attendanceLogDate,
  clockTime,
  convertClockTime,
  dateTimeFormats,
  getFormattedWorkingHour,
} from "../utils/helper";

const MemberProfile = ({}) => {
  const [member, setMember] = useState(null);
  const [attendanceLogs, setAttendanceLogs] = useState([]);
  const [averageWorkingTime, setAverageWorkingTime] = useState("00:00:00");

  const { id } = useParams();

  const loadMember = async () => {
    const member = await getMember(id);
    setMember(member);
  };

  const handleLogUpdate = (log) => {
    const newLogs = attendanceLogs.map((l) => (l.id === log.id ? log : l));
    setAttendanceLogs(newLogs);
  };

  const handleLogDelete = (log) => {
    const remainingLogs = attendanceLogs?.filter((l) => l.id !== log.id);
    setAttendanceLogs(remainingLogs);
  };

  const loadAverageWorkingTime = async () => {
    const average = await getMemberAverageWorkingTime(id);
    setAverageWorkingTime(average || averageWorkingTime);
  };

  const loadAttendanceLogs = async () => {
    const logs = (await getMemberAttendanceLogs(id)) ?? [];
    setAttendanceLogs(logs);
  };

  useEffect(() => {
    loadMember();
    loadAttendanceLogs();
    loadAverageWorkingTime();
  }, []);

  const renderProfileBasicInfo = () => {
    const {
      memberImgUrl,
      memberName,
      memberTitle,
      memberPhone,
      memberEmail,
      timezone,
      firstName,
      lastName,
    } = member ?? {};

    return (
      <div class="card text-center shadow-none">
        <div class="card-body">
          <img
            src={memberImgUrl}
            class="rounded-circle avatar-lg img-thumbnail"
            alt="profile-image"
          />

          <h4 class="mb-0 mt-2">{memberName}</h4>
          <p class="text-muted font-14">{memberTitle}</p>

          <hr />

          <div class="text-start mt-3">
            <p class="text-muted mb-2 font-13">
              <strong>First Name :</strong>
              <span class="ms-2">{firstName}</span>
            </p>

            <p class="text-muted mb-2 font-13">
              <strong>Last Name :</strong>
              <span class="ms-2">{lastName}</span>
            </p>

            <p class="text-muted mb-2 font-13">
              <strong>Mobile :</strong>
              <span class="ms-2">{memberPhone}</span>
            </p>

            <p class="text-muted mb-2 font-13">
              <strong>Email :</strong> <span class="ms-2 ">{memberEmail}</span>
            </p>

            <p class="text-muted mb-1 font-13">
              <strong>Time Zone :</strong> <span class="ms-2">{timezone}</span>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderCheckInTime = () => {
    return (
      <div class="card shadow-none">
        <div class="card-body text-center">
          <span className="badge bg-light-lighten text-muted">
            {attendanceLogDate(member?.checkin)}
          </span>
          <h3 className="d-flex align-items-center justify-content-center">
            <span className="font-15 me-1">
              {convertClockTime(
                member?.lastCheckIn,
                dateTimeFormats.clockTime12Hr
              )}
            </span>{" "}
            <i class="mdi mdi-login text-success"></i>
          </h3>
          <p class="text-muted font-15 mb-0">Check In</p>
        </div>
      </div>
    );
  };

  const renderCheckOutTime = () => (
    <div class="card shadow-none">
      <div class="card-body text-center">
        <span className="badge bg-light-lighten text-muted">
          {attendanceLogDate(member?.checkout)}
        </span>
        <h3 className="d-flex align-items-center justify-content-center">
          <span className="font-15 me-1">
            {convertClockTime(
              member?.lastCheckOut,
              dateTimeFormats.clockTime12Hr
            )}
          </span>{" "}
          <i class="mdi mdi-logout text-danger"></i>
        </h3>
        <p class="text-muted font-15 mb-0">Check Out</p>
      </div>
    </div>
  );

  const renderAverageWorkingTime = () => (
    <div class="card shadow-none">
      <div class="card-body text-center">
        <i
          class="dripicons-graph-line text-muted"
          style={{ fontSize: "24px" }}
        ></i>
        <h3 className="d-flex align-items-center justify-content-center">
          <span className="font-15 me-1">
            {getFormattedWorkingHour(averageWorkingTime)}
          </span>{" "}
          <i class="dripicons-stopwatch text-success"></i>
        </h3>
        <p class="text-muted font-15 mb-0">Average Working Hour</p>
      </div>
    </div>
  );

  const renderPageContent = () => (
    <div className="container-fluid mb-5">
      <div className="row mt-4">
        <div className="col-xl-3 col-lg-4">
          {renderProfileBasicInfo()}
          {renderCheckInTime()}
          {renderCheckOutTime()}
          {renderAverageWorkingTime()}
        </div>
        <div className="col-xl-9 col-lg-8">
          <MemberAttendanceTable
            logs={attendanceLogs}
            onLogUpdate={handleLogUpdate}
            onDelete={handleLogDelete}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {member && renderPageContent()}
      {!member && <MemberProfilePageSkeleton />}
    </>
  );
};

export default MemberProfile;
