import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Login from "./routes/login";
import Dashboard from "./routes/dashboard";
import Members from "./routes/members";
import { getToken, getUser } from "./utils/helper";
import MemberProfile from "./routes/memberProfile";
import Settings from "./routes/settings";
import History from "./routes/history";
import MainNavBar from "./components/mainNavBar";
import Footer from "./components/footer";
import DefaultLayout from "./layouts/defaultLayout";
import GlobalContextWrapper from "./contexts/GlobalContextWrapper";

const ProtectedRoute = ({ children }) => {
  const user = getUser();
  const token = getToken();
  const location = useLocation();

  if (user && token)
    return (
      <GlobalContextWrapper>
        <DefaultLayout>{children}</DefaultLayout>
      </GlobalContextWrapper>
    );
  else return <Navigate to="/login" state={{ from: location }} />;
};

function App() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/members"
          element={
            <ProtectedRoute>
              <Members />
            </ProtectedRoute>
          }
        />
        <Route
          path="/members/:id"
          element={
            <ProtectedRoute>
              <MemberProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/history"
          element={
            <ProtectedRoute>
              <History />
            </ProtectedRoute>
          }
        />
      </Routes>
      <Toaster />
    </>
  );
}

export default App;
