import React from "react";

const MemberPageSkeleton = () => (
  <>
    <div className="container-fluid mt-2 pt-4 pb-4">
      <div
        className="d-flex justify-content-end align-items-center"
        style={{ gap: "2.5rem", height: "3rem" }}
      >
        <div
          className="skeleton w-100 h-100"
          style={{
            boxShadow: "0 0 35px 0 rgb(154 161 171 / 15%)",
            borderRadius: "15px",
          }}
        ></div>
        <div
          className="skeleton w-25 h-100"
          style={{
            boxShadow: "0 0 35px 0 rgb(154 161 171 / 15%)",
            borderRadius: "15px",
          }}
        ></div>
      </div>
    </div>
    <div className="container-fluid mt-2">
      <div
        className="d-flex justify-content-end align-items-center"
        style={{ gap: "2.5rem", height: "35rem" }}
      >
        <div
          className="skeleton w-100 h-100"
          style={{
            boxShadow: "0 0 35px 0 rgb(154 161 171 / 15%)",
            borderRadius: "15px",
          }}
        ></div>
      </div>
    </div>
  </>
);

export default MemberPageSkeleton;
