import moment from "moment";

export const dateTimeFormats = {
  monthNameDateYearDayName: "MMM DD, YYYY, dddd",
  dayNameMonthNameDate: "dddd, MMM DD",
  monthNameDateYear: "MMM DD, YYYY",
  clockTime12Hr: "hh:mm a",
  clockTime24Hr: "HH:mm",
  yearMonthDate: "YYYY-MM-DD",
};

export const getUser = () => JSON.parse(localStorage.getItem("user"));

export const setUser = (user) =>
  localStorage.setItem("user", JSON.stringify(user));

export const getToken = () => localStorage.getItem("token");

export const setToken = (token) => localStorage.setItem("token", token);

export const removeUser = () => localStorage.removeItem("user");

export const logUserOut = (reason = "") => {
  removeUser();
  if (reason) window.history.pushState({ type: reason }, "", "/login");
  window.location.replace("/login");
};

export const attendanceLogDate = (date) => {
  if (!date) return "No log date found!";

  const today = moment();
  const logDate = moment(date);
  const daysPassedSinceLastCheckIn = today.diff(logDate, "d");
  const day = !daysPassedSinceLastCheckIn
    ? "Today"
    : daysPassedSinceLastCheckIn === 1
    ? "Yesterday"
    : logDate.format(dateTimeFormats.monthNameDateYearDayName);

  return day;
};

export const formatDate = (
  date,
  outputFormat = dateTimeFormats.monthNameDateYearDayName
) => (date ? moment(date).format(outputFormat) : null);

export const convertClockTime = (
  clockTime,
  outputFormat = dateTimeFormats.clockTime12Hr
) => {
  const time =
    outputFormat === dateTimeFormats.clockTime12Hr
      ? moment(clockTime, dateTimeFormats.clockTime24Hr).format(outputFormat)
      : moment(clockTime, dateTimeFormats.clockTime12Hr).format(outputFormat);

  return time === "Invalid date" ? "-" : time;
};

export const clockTime = (date, outputFormat = dateTimeFormats.clockTime12Hr) =>
  date ? moment(date).format(outputFormat) : "-";

export const getFormattedWorkingHour = (workingHour) => {
  if (!workingHour) return "";

  const [hours, mins] = workingHour.split(":");
  return `${hours} hrs ${mins} mins`;
};

export const scrollToTop = (element = document.body) => {
  try {
    element?.scrollIntoView();
  } catch (err) {
    console.log(err);
  }
};
