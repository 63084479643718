import React, { useEffect, useState } from "react";
import {
  attendanceLogDate,
  clockTime,
  getFormattedWorkingHour,
} from "../../utils/helper";
import Button from "../common/button";
import Pagination from "../common/pagination";
import MemberAttendanceLogRow from "./memberAttendanceLogRow";

const MemberAttendanceTable = ({
  logs = [],
  onLogUpdate = () => {},
  onDelete,
}) => {
  const [pageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalLogs, setTotalLogs] = useState(logs.length);

  useEffect(() => {
    setTotalLogs(logs.length);
  }, [logs]);

  const firstRowSerial = Math.min(totalLogs, (currentPage - 1) * pageSize + 1);
  const lastRowSerial = Math.min(totalLogs, pageSize * currentPage);

  const getCurrentPageInfo = () => {
    return `Showing ${firstRowSerial} to ${lastRowSerial} of ${totalLogs} logs`;
  };

  const currentPageRows = () => {
    return logs.filter(
      (l, idx) => idx + 1 >= firstRowSerial && idx + 1 <= lastRowSerial
    );
  };

  const renderTableTitle = () => (
    <div className="d-flex justify-content-between align-items-center mb-2">
      <h4 className="header-title mb-0">
        Attandance Log <span className="text-muted fs-6">- last 30 days</span>
      </h4>
      <Button className="text-info text-capitalize badge bg-info-lighten fs-6">
        download <i className="mdi mdi-download"></i>
      </Button>
    </div>
  );

  const renderTableHeaders = () => (
    <thead>
      <tr>
        <th>Date</th>
        <th>Check In</th>
        <th>Check Out</th>
        <th>Hours Logged</th>
        <th></th>
      </tr>
    </thead>
  );

  const renderPagination = () => (
    <div className="d-flex justify-content-between align-items-center mt-3">
      <span className="text-secondary">
        <b>{getCurrentPageInfo()}</b>
      </span>

      <Pagination
        totalItems={totalLogs}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={setCurrentPage}
      />
    </div>
  );

  const renderTableRows = () => (
    <tbody>
      {currentPageRows().map((l, idx) => (
        <MemberAttendanceLogRow
          log={l}
          key={l.id}
          onUpdate={onLogUpdate}
          onDelete={onDelete}
        />
      ))}
    </tbody>
  );

  const renderTable = () => (
    <div className="table-responsive">
      <table className="table table-centered table-nowrap mb-0">
        {renderTableHeaders()}
        {renderTableRows()}
      </table>
    </div>
  );

  return (
    <div className="card">
      <div className="card-body">
        {renderTableTitle()}
        {renderTable()}
        {renderPagination()}
      </div>
    </div>
  );
};

export default MemberAttendanceTable;
