import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../utils/helper";
import Button from "../common/button";
import Pagination from "../common/pagination";

const AbsentMembersList = ({ list }) => {
  const [pageSize] = useState(24);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(list?.length);

  const navigate = useNavigate();

  const absentMemberTableId = "absent-table";

  useEffect(() => {
    scrollToTop(document.getElementById(absentMemberTableId));
  }, [currentPage]);

  useEffect(() => {
    setTotal(list?.length);
  }, [list]);

  const firstRowSerial = Math.min(total, (currentPage - 1) * pageSize + 1);
  const lastRowSerial = Math.min(total, pageSize * currentPage);

  const currentPageMembers = () => {
    return list?.filter(
      (l, idx) => idx + 1 >= firstRowSerial && idx + 1 <= lastRowSerial
    );
  };

  const getCurrentPageInfo = () => {
    return `Showing ${firstRowSerial} to ${lastRowSerial} of ${total} absentees`;
  };

  const renderTitle = () => (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <h4 className="header-title mb-0">Absent Members</h4>
      {/* {list?.length && (
        <Button className="text-info text-capitalize badge bg-info-lighten fs-6">
          download <i className="mdi mdi-download"></i>
        </Button>
      )} */}
    </div>
  );

  const renderList = () => {
    return currentPageMembers()?.map((m, idx) => (
      <div
        key={idx}
        class="card mb-1 shadow-none border c-pointer"
        onClick={(e) => navigate(`/members/${m.memberId}`)}
      >
        <div class="p-2">
          <div class="row align-items-center">
            <div class="col-auto">
              <img
                src={m.memberImgUrl}
                class="avatar-sm rounded"
                alt="file-image"
              />
            </div>
            <div class="col ps-0">
              <p class="text-muted fw-bold m-0">
                {m.memberDisplayName || m.memberName}
              </p>
              <p class="mb-0">{m.memberTitle}</p>
            </div>
            <div class="col-auto">
              <a
                href="javascript:void(0);"
                class="btn btn-link btn-lg text-info"
              >
                <i class="mdi mdi-chevron-double-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const renderPaginationSection = () => (
    <div className="d-flex justify-content-between align-items-center mt-3">
      <span className="text-secondary">
        <b>{getCurrentPageInfo()}</b>
      </span>

      <Pagination
        totalItems={total}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </div>
  );

  return (
    <div
      className={`card mb-0 h-100 ${!list && "skeleton"}`}
      id={absentMemberTableId}
    >
      <div className="card-body">
        {renderTitle()}
        {list && (
          <>
            {renderList()}
            {renderPaginationSection()}
          </>
        )}
      </div>
    </div>
  );
};

export default AbsentMembersList;
