import { logUserOut } from "../utils/helper";
import http from "./httpService";

const baseUrl = `/members`;

export const getAllMembers = async () => {
  try {
    const { data } = await http.get(`${baseUrl}`);
    return data;
  } catch (err) {
    return null;
  }
};

export const getAllMembersWithDetails = async () => {
  try {
    const { data } = await http.get(`${baseUrl}/details`);
    return data;
  } catch (err) {
    return {};
  }
};

export const getMember = async (memberId) => {
  try {
    const { data } = await http.get(`${baseUrl}/${memberId}`);
    return data;
  } catch (err) {
    return null;
  }
};

export const updateMember = async (memberId, data) => {
  try {
    const {
      status,
      data: { message },
    } = await http.put(`${baseUrl}/${memberId}`, data);
    return { status, message };
  } catch (err) {
    return {};
  }
};

export const deleteMember = async (memberId) => {
  try {
    const { status } = await http.delete(`${baseUrl}/${memberId}`);
    return status;
  } catch (err) {
    return null;
  }
};
