import React, { useState } from "react";
import toast from "react-hot-toast";
import { createAdmin } from "../../services/adminService";
import Button from "../common/button";

const CreateAdminForm = ({ member, onClose = () => {} }) => {
  const {
    memberId,
    memberName,
    memberTitle,
    memberImgUrl,
    isAdmin,
    memberEmail,
  } = member;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    const { status, message } = await createAdmin(
      {
        name: memberName,
        email: memberEmail || email,
        memberId,
      },
      password
    );
    if (status === 200) {
      toast.success(message);
      onClose();
    } else {
      toast.error("Sorry, something went wrong!");
      setFormSubmitted(false);
    }
  };

  return (
    <>
      <div
        id="signup-modal"
        class="modal fade show"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style={{ display: "block", paddingRight: "15px" }}
        onClick={onClose}
      >
        <div
          class="modal-dialog modal-dialog-centered"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="modal-content">
            <div class="modal-body">
              <div class="auth-brand text-center mt-2 mb-4 position-relative top-0">
                <h4 className="modal-title text-info">Make Admin</h4>
              </div>

              <form class="ps-3 pe-3" onSubmit={handleSubmit}>
                <div class="mb-3">
                  <label for="username" class="form-label">
                    Name
                  </label>
                  <input
                    class="form-control"
                    type="text"
                    value={memberName}
                    id="username"
                    disabled
                    placeholder="Michael Zenaty"
                  />
                </div>

                <div class="mb-3">
                  <label for="emailaddress" class="form-label">
                    Email address
                  </label>
                  <input
                    class="form-control"
                    type="email"
                    value={memberEmail || email}
                    id="emailaddress"
                    disabled={memberEmail}
                    required=""
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="john@deo.com"
                  />
                </div>

                <div class="mb-3">
                  <label for="password" class="form-label">
                    Password
                  </label>
                  <input
                    class="form-control"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    placeholder="Please set a password for the new admin"
                  />
                </div>

                <div class="mb-3 text-center">
                  <Button
                    className="btn btn-info"
                    type="submit"
                    disabled={formSubmitted}
                  >
                    {!formSubmitted ? (
                      "Submit"
                    ) : (
                      <>
                        <span
                          class="spinner-border spinner-border-sm me-2"
                          role="status"
                        ></span>
                        Creating admin...
                      </>
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default CreateAdminForm;
