import React, { useState } from "react";
import toast from "react-hot-toast";
import { createAdmin } from "../../services/adminService";
import { updateMember } from "../../services/memberService";
import Button from "../common/button";

const ChangeStatusForm = ({ member, onClose = () => {} }) => {
  const {
    memberId,
    memberName,
    memberTitle,
    memberImgUrl,
    isAdmin,
    memberEmail,
    status: memberStatus,
  } = member;

  const [status, setStatus] = useState(memberStatus);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    const { status: responseStatus, message } = await updateMember(memberId, {
      status,
    });
    if (responseStatus === 200) {
      toast.success(message);
      onClose();
    } else {
      toast.error("Sorry, something went wrong!");
      setFormSubmitted(false);
    }
  };

  return (
    <>
      <div
        id="status-modal"
        class="modal fade show"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
        style={{ display: "block", paddingRight: "15px" }}
        onClick={onClose}
      >
        <div
          class="modal-dialog modal-dialog-centered"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="modal-content">
            <div class="modal-body">
              <div class="auth-brand text-center mt-2 mb-4 position-relative top-0">
                <h4 className="modal-title text-info">Make Admin</h4>
              </div>

              <form class="ps-3 pe-3" onSubmit={handleSubmit}>
                <div class="mb-3">
                  <label for="username" class="form-label">
                    Name
                  </label>
                  <input
                    class="form-control"
                    type="text"
                    value={memberName}
                    id="username"
                    disabled
                    placeholder="Michael Zenaty"
                  />
                </div>

                <div class="mb-3">
                  <label for="emailaddress" class="form-label">
                    Email address
                  </label>
                  <input
                    class="form-control"
                    type="email"
                    value={memberEmail}
                    id="emailaddress"
                    disabled
                    required=""
                    placeholder="john@deo.com"
                  />
                </div>

                <div class="mb-3">
                  <label for="statuses" class="form-label">
                    Status
                  </label>
                  <select
                    className="form-select"
                    id="statuses"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    {["ACTIVE", "INACTIVE", "HIDDEN"].map((s) => (
                      <option value={s}>{s}</option>
                    ))}
                  </select>
                </div>

                <div class="mb-3 text-center">
                  <Button
                    className="btn btn-info"
                    type="submit"
                    disabled={formSubmitted}
                  >
                    {!formSubmitted ? (
                      "Submit"
                    ) : (
                      <>
                        <span
                          class="spinner-border spinner-border-sm me-2"
                          role="status"
                        ></span>
                        Updating Status...
                      </>
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default ChangeStatusForm;
