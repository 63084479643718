import React, { useEffect, useState } from "react";
import { downloadLogs } from "../../services/attendanceService";
import {
  clockTime,
  getFormattedWorkingHour,
  scrollToTop,
} from "../../utils/helper";
import Button from "../common/button";
import Pagination from "../common/pagination";
import AttendanceLogRow from "./attendanceLogRow";

const AttendanceLogTable = ({ logs, onLogUpdate = () => {}, onDelete }) => {
  const [pageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalLogs, setTotalLogs] = useState(logs?.length);
  const [downloading, setDownloading] = useState(false);

  const logTableId = "log-table";

  useEffect(() => {
    scrollToTop(document.getElementById(logTableId));
  }, [currentPage]);

  useEffect(() => {
    setTotalLogs(logs?.length);
  }, [logs]);

  const handleLogDownload = async () => {
    setDownloading(true);
    await downloadLogs();
    setDownloading(false);
  };

  const firstRowSerial = Math.min(totalLogs, (currentPage - 1) * pageSize + 1);
  const lastRowSerial = Math.min(totalLogs, pageSize * currentPage);

  const getCurrentPageInfo = () => {
    return `Showing ${firstRowSerial} to ${lastRowSerial} of ${totalLogs} logs`;
  };

  const currentPageRows = () => {
    return logs?.filter(
      (l, idx) => idx + 1 >= firstRowSerial && idx + 1 <= lastRowSerial
    );
  };

  const renderTableTitle = () => (
    <div className="d-flex justify-content-between align-items-center mb-2">
      <h4 className="header-title mb-0">Attandance Log</h4>
      {logs?.length && (
        <Button
          className="text-info text-capitalize badge bg-info-lighten fs-6"
          onClick={handleLogDownload}
          disabled={downloading}
        >
          download{" "}
          {downloading ? (
            <div class="spinner-border spinner-border-sm" role="status"></div>
          ) : (
            <i className="mdi mdi-download"></i>
          )}
        </Button>
      )}
    </div>
  );

  const renderTableHeaders = () => (
    <thead>
      <tr>
        <th>Name</th>
        <th>Check In</th>
        <th>Check Out</th>
        <th>Hours Logged</th>
        <th></th>
      </tr>
    </thead>
  );

  const renderPagination = () => (
    <div className="d-flex justify-content-between align-items-center mt-3">
      <span className="text-secondary">
        <b>{getCurrentPageInfo()}</b>
      </span>

      <Pagination
        totalItems={totalLogs}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={setCurrentPage}
      />
    </div>
  );

  const renderTableRows = () => (
    <tbody>
      {currentPageRows().map((l) => (
        <AttendanceLogRow
          log={l}
          key={l.id}
          onUpdate={onLogUpdate}
          onDelete={onDelete}
        />
      ))}
    </tbody>
  );

  const renderTable = () => (
    <div className="table-responsive">
      <table className="table table-centered table-nowrap mb-0">
        {renderTableHeaders()}
        {renderTableRows()}
      </table>
    </div>
  );

  return (
    <div className={`card mb-0 h-100 ${!logs && "skeleton"}`} id={logTableId}>
      <div className="card-body">
        {renderTableTitle()}
        {logs && (
          <>
            {renderTable()}
            {renderPagination()}
          </>
        )}
      </div>
    </div>
  );
};

export default AttendanceLogTable;
