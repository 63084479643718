import React from "react";
import Footer from "../components/footer";
import MainNavBar from "../components/mainNavBar";

const DefaultLayout = ({ children }) => (
  <>
    <MainNavBar />
    {children}
    <Footer />
  </>
);

export default DefaultLayout;
