import moment from "moment";
import { dateTimeFormats, logUserOut } from "../utils/helper";
import http from "./httpService";

const baseUrl = `/statistics`;

export const getShortHighlights = async () => {
  try {
    const { data } = await http.get(`${baseUrl}/short-highlights`);
    return data;
  } catch (err) {
    return null;
  }
};

export const getMissingCheckoutsOverview = async () => {
  try {
    const { data } = await http.get(`${baseUrl}/checkout/missing`);
    return data;
  } catch (err) {
    return null;
  }
};

/**
 *
 * @param {string} date **format**: 'YYYY-MM-DD', **default**: today
 */
export const getListOfLateCheckInByDate = async (date = moment().format(dateTimeFormats.yearMonthDate)) => {
  try {
    const { data } = await http.get(`${baseUrl}/late-check-ins`, {
      params: {
        date,
      },
    });
    return data;
  } catch (err) {
    return null;
  }
};
