import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { mainNavMenuItems } from "../config";
import { getUser, removeUser } from "../utils/helper";
import Button from "./common/button";

const MainNavBar = () => {
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [showMainMenu, setShowMainMenu] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const user = getUser();

  useEffect(() => {
    const body = document.getElementsByTagName("body")?.[0];
    body.setAttribute("data-layout", "topnav");
  }, []);

  const isActiveMenuItem = (path) => location.pathname.includes(path);

  const handleLogOut = () => {
    removeUser();
    navigate("/login");
  };

  return (
    <>
      <div className="topnav-navbar navbar-custom">
        <div className="container-fluid">
          <Link to="/dashboard" className="topnav-logo">
            <span className="topnav-logo-lg fs-4 text-info text-capitalize">
              <img
                src="/favicon.ico"
                height="16"
                className="me-1"
                style={{ marginTop: "-3px" }}
              />
              attendance report
            </span>
            <span className="topnav-logo-sm fs-1">
              <img src="/favicon.ico" height="16" />
            </span>
          </Link>

          <Button
            className="d-lg-none float-end fs-2 mt-1"
            onClick={() => setShowMainMenu(!showMainMenu)}
          >
            <i
              className={`uil ${showMainMenu ? "uil-multiply" : "uil-bars"}`}
            ></i>
          </Button>

          <ul className="list-unstyled topbar-menu float-end mb-0">
            <li
              className="dropdown notification-list"
              onClick={(e) => setShowProfileDropdown(!showProfileDropdown)}
            >
              <a
                role="button"
                className="nav-link dropdown-toggle nav-user arrow-none me-0"
              >
                <span class="account-user-avatar">
                  {user.imgUrl && (
                    <img
                      src={user.imgUrl}
                      alt="user-image"
                      class="rounded-circle"
                    />
                  )}
                </span>
                <span>
                  <span class="account-user-name">{user.name}</span>
                  <span class="account-position">{user.title} / Admin</span>
                </span>
              </a>
              <div
                class={`dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown ${
                  showProfileDropdown && "show"
                }`}
                aria-labelledby="topbar-userdrop"
              >
                <Link
                  className="dropdown-item notify-item"
                  data-bs-toggle="dropdown"
                  id="topbar-userdrop"
                  role="button"
                  aria-haspopup="true"
                  onClick={(e) => setShowProfileDropdown(false)}
                  to="/settings"
                >
                  <i class="mdi mdi-account-edit me-1"></i>
                  <span>Settings</span>
                </Link>
                <Button
                  className="dropdown-item notify-item"
                  onClick={handleLogOut}
                >
                  <i class="mdi mdi-logout me-1"></i>
                  <span>Logout</span>
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="topnav shadow-sm">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            style={{ width: "100%" }}
          >
            <div
              className={`collapse navbar-collapse ${showMainMenu && "show"}`}
            >
              <ul className="navbar-nav">
                {mainNavMenuItems.map((item, idx) => (
                  <li
                    className={`nav-item dropdown ${
                      isActiveMenuItem(item.path) && "active"
                    }`}
                    key={idx}
                  >
                    <Link
                      to={item.path}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className={item.icon}></i> {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default MainNavBar;
