import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/footer";
import LoginForm from "../components/login/loginForm";
import { getUser } from "../utils/helper";

const loginPageBackgroundClass = "authentication-bg";

const Login = () => {
  const navigate = useNavigate();

  // redirect user back to homepage if user is already logged in
  useEffect(() => {
    const loggedInUser = getUser();
    if (loggedInUser) navigate("/dashboard");
  }, [navigate]);

  // set the page title
  useEffect(() => {
    document.title = "Login | Slack bot dashboard";

    const el = document.getElementsByTagName("body");
    el?.[0].classList.add(loginPageBackgroundClass);

    return () => el?.[0].classList.remove(loginPageBackgroundClass);
  }, []);

  return (
    <>
      <LoginForm />
      <Footer />
    </>
  );
};;

export default Login;