import React from "react";

const ShortHighlightsCard = ({ title, icon, value, infoText }) => {
  return (
    <div
      class={`card h-100 mb-0 ${value ?? "skeleton empty-card-sm"}`}
      style={{ borderRadius: "0.5rem" }}
    >
      <div class="card-body pt-3 pb-3">
        <div class="d-flex align-items-top ps-1 pe-1">
          <div class="flex-shrink-0">{icon}</div>
          <div class="flex-grow-1 ms-3">
            <h6 class="mt-0 mb-1 text-uppercase">{title}</h6>
            <h4 class="mt-0 mb-1 font-20">
              {value ? String(value).padStart(2, "0") : value}
              <span class="ms-1 mb-0 text-muted fs-6">{infoText}</span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShortHighlightsCard;
