import React, { useState } from "react";
import _ from "lodash";

import {
  clockTime,
  convertClockTime,
  dateTimeFormats,
  getFormattedWorkingHour,
} from "../../utils/helper";
import {
  deleteAttendanceLog,
  updateAttendanceLog,
} from "../../services/attendanceService";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import DeleteLogOverlay from "../common/deleteLogOverlay";
import workType from "../../enums/workType";

const AttendanceLogRow = ({ log, onUpdate = () => {}, onDelete }) => {
  const [editMode, setEditMode] = useState(false);
  const [checkInClockTime, setCheckInClockTime] = useState(log.checkIn);
  const [checkOutClockTime, setCheckOutClockTime] = useState(log.checkout);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showDeleteLogConfirm, setShowDeleteLogConfirm] = useState(false);

  const resetData = () => {
    setCheckInClockTime(log.checkIn);
    setCheckOutClockTime(log.checkout);
    setEditMode(false);
  };

  const validateData = () => {
    let result = true;
    if (convertClockTime(checkInClockTime) === "-") {
      toast.error("Check in time can't be empty!");
      result = false;
    }

    return result;
  };

  const updateLog = async () => {
    if (!validateData()) return;

    setEditMode(false);
    setIsUpdating(true);

    const { status, log: updatedLog } = await updateAttendanceLog(log.id, {
      checkInClockTime,
      checkOutClockTime,
    });

    if (status !== 200) {
      toast.error(`Failed to update attendace log of ${log.memberName}`);
      resetData();
    } else {
      onUpdate({ ...log, ...updatedLog });
    }
    setIsUpdating(false);
  };

  const deleteLog = async () => {
    setIsUpdating(true);
    setShowDeleteLogConfirm(false);

    const { status } = await deleteAttendanceLog(log.id);
    if (status === 200) {
      onDelete(log);
    } else {
      toast.error(
        `Failed to delete log at ${log.workDate} for ${log.memberDisplayName} `
      );
      setIsUpdating(false);
    }
  };

  const renderActionButton = () => {
    if (isUpdating) {
      return (
        <span
          class="spinner-border spinner-border-sm text-primary"
          role="status"
        ></span>
      );
    } else if (editMode) {
      return (
        <>
          <i className="uil uil-redo text-danger me-2" onClick={resetData}></i>
          <i className="uil  uil-file-alt text-primary" onClick={updateLog}></i>
        </>
      );
    } else
      return (
        <>
          <i
            className="mdi mdi-pencil text-success me-2"
            onClick={(e) => setEditMode(true)}
          ></i>
          <i
            className="uil uil-trash text-danger"
            onClick={(e) => setShowDeleteLogConfirm(true)}
          ></i>
        </>
      );
  };

  return (
    <tr style={{ position: "relative" }}>
      <td>
        <Link to={`/members/${log.memberId}`} className="text-color-inherit">
          <div class="d-flex align-items-center hover-underline">
            <div class="flex-shrink-0">
              <img
                class="rounded-circle"
                src={log.memberImgUrl}
                alt="Avtar image"
                width="33"
              />
            </div>
            <div class="flex-shrink-0 ms-2">
              {log.memberDisplayName || log.memberName}
            </div>
            {log.workType === workType.REMOTE && (
              <i
                className="mdi mdi-home-city-outline text-info fs-4 ms-2"
                title="working remotely"
              ></i>
            )}
          </div>
        </Link>
      </td>
      <td>
        <i className="mdi mdi-login text-success me-2"></i>
        {!editMode && <span>{convertClockTime(checkInClockTime)}</span>}
        {editMode && (
          <input
            className="form-control w-auto d-inline inline-input"
            type="time"
            autoFocus
            value={checkInClockTime}
            onChange={(e) => setCheckInClockTime(e.target.value)}
          />
        )}
      </td>

      <td>
        <i className="mdi mdi-logout text-danger me-2"></i>
        {!editMode && <span>{convertClockTime(checkOutClockTime)}</span>}
        {editMode && (
          <input
            className="form-control w-auto d-inline inline-input"
            type="time"
            value={checkOutClockTime}
            onChange={(e) => setCheckOutClockTime(e.target.value)}
          />
        )}
      </td>
      <td>
        <i className="dripicons-clock me-1"></i>
        <span className="badge bg-success-lighten text-success fs-6">
          {getFormattedWorkingHour(log.workingHour)}
        </span>
      </td>
      <td className="text-start c-pointer fs-4">{renderActionButton()}</td>

      {showDeleteLogConfirm && (
        <DeleteLogOverlay
          onCancel={() => setShowDeleteLogConfirm(false)}
          onDelete={deleteLog}
        />
      )}
    </tr>
  );
};

export default AttendanceLogRow;
