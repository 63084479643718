import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  attendanceLogDate,
  clockTime,
  convertClockTime,
  dateTimeFormats,
} from "../../utils/helper";

const MemberCard = ({
  member = {},
  onMakeAdminClick = () => {},
  onChangeStatusClick = () => {},
  onDelete = () => {},
}) => {
  const {
    memberId,
    memberDisplayName,
    memberName,
    memberTitle,
    memberImgUrl,
    isAdmin,
    isDashboardAdmin,
    lastCheckIn,
    checkin,
  } = member;

  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div
      class="card mb-0 h-100 ribbon-box c-pointer hover-shadow"
      onClick={(e) => navigate(`./${memberId}`)}
    >
      <div class="card-body">
        <div className="p-absolute">
          {isAdmin && (
            <div class="ribbon ribbon-info float-start">
              <i class="uil uil-slack me-1"></i> Slack Admin
            </div>
          )}

          {isDashboardAdmin ? (
            <div class="ribbon ribbon-info float-start">
              <i class="uil uil-shield me-1"></i> Admin
            </div>
          ) : null}
        </div>

        <div class="dropdown float-end dropleft">
          <a
            role="button"
            className="dropdown-toggle arrow-none card-drop"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={(e) => {
              e.stopPropagation();
              setShowDropdown(!showDropdown);
            }}
          >
            <i class="mdi mdi-dots-horizontal"></i>
          </a>
          <div className={`dropdown-menu ${showDropdown && "show"}`}>
            <span class="dropdown-item">View Profile</span>

            {!isDashboardAdmin && (
              <span
                class="dropdown-item"
                onClick={(e) => {
                  e.stopPropagation();
                  onMakeAdminClick(member);
                  setShowDropdown(false);
                }}
              >
                Make Admin
              </span>
            )}

            <span
              class="dropdown-item text-info"
              onClick={(e) => {
                e.stopPropagation();
                onChangeStatusClick(member);
                setShowDropdown(false);
              }}
            >
              Change Status
            </span>

            <span
              class="dropdown-item text-danger"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(member);
                setShowDropdown(false);
              }}
            >
              Delete
            </span>
          </div>
        </div>

        <div class="ribbon-content text-center">
          <img
            src={memberImgUrl}
            class="rounded-circle avatar-md img-thumbnail"
            alt="friend"
          />
          <h4 class="mt-3 my-1">{memberDisplayName || memberName}</h4>
          <p class="mb-0 text-muted d-flex align-items-center justify-content-center">
            <i class="uil uil-clock me-1"></i>
            <span className="badge badge-primary-lighten">
              {lastCheckIn && (
                <>
                  {attendanceLogDate(checkin)}
                  {" at "}{" "}
                  {convertClockTime(lastCheckIn, dateTimeFormats.clockTime12Hr)}
                </>
              )}
              {!lastCheckIn && "No attendance log"}
            </span>
          </p>
          <hr class="bg-dark-lighten my-3" />
          <h5 class="mt-3 fw-semibold text-muted">{memberTitle || "-"}</h5>
        </div>
      </div>
    </div>
  );
};

export default MemberCard;
