import http from "./httpService";

const baseUrl = `/admins`;

export const createAdmin = async (memberData, password) => {
  try {
    const {
      data: { message },
      status,
    } = await http.post(baseUrl, {
      ...memberData,
      password,
    });

    return { status, message };
  } catch (err) {
    return {};
  }
};

export const updateAdmin = async (id, updateData) => {
  try {
    const {
      data: { message },
      status,
    } = await http.put(`${baseUrl}/${id}`, updateData);

    return { status, message };
  } catch (err) {
    return {};
  }
};
