import http from "./httpService";

const baseUrl = `/auth`;

export const authenticateUser = async (email, password) => {
  try {
    const {
      status,
      data: { user, token },
    } = await http.post(`${baseUrl}/login`, {
      email,
      password,
    });

    return { user, token, status };
  } catch (err) {
    return { status: err.response.status };
  }
};
