export const mainNavMenuItems = [
  {
    label: "Dashboard",
    path: "/dashboard",
    icon: "",
  },
  {
    label: "Members",
    path: "/members",
    icon: "",
  },
  {
    label: "History",
    path: "/history",
    icon: "",
  },
];

export const memberFilters = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Admins",
    value: "admin",
  },
];

export const shortHighlights = [
  {
    title: "Total",
    key: "totalMembers",
    icon: (
      <i class="mdi mdi-account-multiple widget-icon bg-primary-lighten text-primary"></i>
    ),
  },
  {
    title: "Check Ins",
    key: "checkIns",
    icon: (
      <i class="mdi mdi-login widget-icon bg-success-lighten text-success"></i>
    ),
  },
  {
    title: "Working Remotely",
    key: "remoteCheckIns",
    icon: (
      <i class="mdi mdi-home-city-outline widget-icon bg-info-lighten text-info"></i>
    ),
  },
  {
    title: "Check Outs",
    key: "checkOuts",
    icon: (
      <i class="mdi mdi-logout widget-icon bg-danger-lighten text-danger"></i>
    ),
  },
  // {
  //   title: "Active Members",
  //   key: "onlineNow",
  //   icon: (
  //     <i class="mdi mdi-account-multiple widget-icon bg-success-lighten text-success"></i>
  //   ),
  // },
];
