import moment from "moment";
import { dateTimeFormats, logUserOut } from "../utils/helper";
import http from "./httpService";

const baseUrl = `/attendance`;

export const getAttendanceLogs = async () => {
  try {
    const { data } = await http.get(baseUrl);
    return data;
  } catch (err) {
    return null;
  }
};

export const getMemberAttendanceLogs = async (memberId) => {
  try {
    const { data } = await http.get(`${baseUrl}/member/${memberId}`);
    return data.logs;
  } catch (err) {
    return null;
  }
};

export const getMemberAverageWorkingTime = async (memberId) => {
  try {
    const {
      data: { averageWorkingHour },
    } = await http.get(`${baseUrl}/member/${memberId}/average-working-time`);
    return averageWorkingHour;
  } catch (err) {
    return null;
  }
};

export const updateAttendanceLog = async (id, data) => {
  try {
    const {
      data: { log },
      status,
    } = await http.put(`${baseUrl}/${id}`, data);
    return { status, log };
  } catch (err) {
    return {};
  }
};

export const createAttendanceLog = async (data) => {
  try {
    const {
      data: { log },
      status,
    } = await http.post(`${baseUrl}`, data);
    return { status, log };
  } catch (err) {
    return {};
  }
};

export const deleteAttendanceLog = async (id) => {
  try {
    const { status } = await http.delete(`${baseUrl}/${id}`);
    return { status };
  } catch (err) {
    return {};
  }
};

export const getLogHistory = async ({
  startDate,
  endDate = null,
  page = 1,
  pageSize = 50,
  memberIds = "",
}) => {
  try {
    const {
      data: { logs, pagination },
      status,
    } = await http.get(`${baseUrl}/history`, {
      params: {
        startDate: moment(startDate).format(dateTimeFormats.yearMonthDate),
        endDate: endDate
          ? moment(endDate).format(dateTimeFormats.yearMonthDate)
          : null,
        page,
        pageSize,
        memberIds,
      },
    });

    return { status, logs, pagination };
  } catch (err) {
    return {};
  }
};

export const downloadLogs = async (startDate, endDate, memberIds) => {
  const params = {};
  startDate && (params.startDate = startDate);
  endDate && (params.endDate = endDate);
  memberIds && (params.memberIds = memberIds);

  const { data, headers } = await http.get(`/download`, {
    responseType: "blob",
    params,
  });

  const href = URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute(
    "download",
    headers["content-disposition"]
      .match(/filename="[\w\W]*?"/gim)?.[0]
      .replace(/filename="|"$/gim, "")
  );

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);

  return true;
};
