import React from "react";
import MainNavBar from "../components/mainNavBar";
import Footer from "../components/footer";
import { getUser, setUser } from "../utils/helper";
import { useState } from "react";
import Joi from "joi";
import { useEffect } from "react";
import Input from "../components/common/input";
import Button from "../components/common/button";
import { updateAdmin } from "../services/adminService";
import toast from "react-hot-toast";

const settingsFormSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .messages({
      "string.empty": "Please enter your email address!",
      "string.email": "Please enter a valid email address!",
    }),
  password: Joi.string()
    .allow("")
    .min(2)
    .messages({ "string.min": "Password can't be 1 character long!" }),
  reTypedPassword: Joi.string().valid(Joi.ref("password")).messages({
    "any.only": "Your re-typed password did not match your new password!",
    "string.empty": "Please re-type your new password!",
  }),
});

const Settings = () => {
  const { id, email: prevEmail, name, title, imgUrl } = getUser();

  const [email, setEmail] = useState(prevEmail);
  const [password, setPassword] = useState("");
  const [reTypedPassword, setReTypedPassword] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(null);

  useEffect(() => {
    !password && setReTypedPassword("");
  }, [password]);

  const noDataChanged = email === prevEmail && !password;

  const submitButtonDisabled = formSubmitted || noDataChanged;

  const validateForm = () => {
    const { error } = settingsFormSchema.validate(
      { email, password, reTypedPassword },
      { abortEarly: false }
    );

    if (!error) {
      setFormError(null);
      return true;
    }

    const formError = error.details.reduce(
      (err, currentError) => ({
        ...err,
        [currentError.context.key]: currentError.message,
      }),
      {}
    );

    setFormError(formError);
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setFormSubmitted(true);
    const { status } = await updateAdmin(id, { email, password });

    if (status === 200) {
      toast.success("Settings updated!");
      setUser({ ...getUser(), email });
    } else toast.error("Something went wrong!");
    setFormSubmitted(false);
  };

  const renderSettingsForm = () => {
    return (
      <div className="row m-md-5 mt-5 mb-5 justify-content-center">
        <div className="col-xxl-3 col-lg-4">
          <div class="card">
            <div class="card-header pt-3 pb-2 text-center">
              <span>
                {imgUrl && (
                  <img
                    src={imgUrl}
                    className="rounded-circle avatar-lg img-thumbnail"
                  />
                )}
              </span>
              <div class="text-center w-75 m-auto mt-3">
                <h4 class="text-dark-50 text-center mt-0 fw-bold">{name}</h4>
                <p class="text-muted">{`${title ? title + " / " : ""}Admin`}</p>
              </div>
            </div>

            <div class="card-body p-4">
              <form
                onSubmit={handleSubmit}
                className={`${formError && "was-validated"}`}
              >
                <Input
                  type="email"
                  id="emailaddress"
                  value={email}
                  onChange={setEmail}
                  placeholder="Enter your email"
                  error={formError?.email}
                  label="Email"
                />
                <Input
                  class="form-control"
                  type="password"
                  id="password"
                  value={password}
                  onChange={setPassword}
                  placeholder="Enter a new password..."
                  error={formError?.password}
                  label="New Password"
                />

                {password && (
                  <Input
                    class="form-control"
                    type="password"
                    id="re-password"
                    value={reTypedPassword}
                    onChange={setReTypedPassword}
                    placeholder="Please type your new password again"
                    label="Re-type your new password"
                    error={formError?.reTypedPassword}
                  />
                )}

                <div class="mb-0 text-center">
                  <Button
                    className="btn-info"
                    type="submit"
                    disabled={submitButtonDisabled}
                    onClick={handleSubmit}
                  >
                    {formSubmitted ? (
                      <>
                        <span
                          class="spinner-border spinner-border-sm me-2"
                          role="status"
                        ></span>
                        Updating ...
                      </>
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <>{renderSettingsForm()}</>;
};

export default Settings;
