import React, { useEffect, useState } from "react";
import LateCheckInHighlight from "../components/cards/LateCheckInHighlight";
import ShortHighlightsCard from "../components/cards/ShortHighlightsCard";
import AbsentMembersList from "../components/dashboard/absentMembersList";
import AttendanceLogTable from "../components/dashboard/AttendanceLogTable";
import EarlyBirdInfo from "../components/dashboard/earlyBirdInfo";
import Footer from "../components/footer";
import MainNavBar from "../components/mainNavBar";
import { shortHighlights } from "../config";
import { getAttendanceLogs } from "../services/attendanceService";
import { getMissingCheckoutsOverview, getShortHighlights } from "../services/statisticsService";

const Dashboard = () => {
  const [shortHighlightsInfo, setShortHighlightsInfo] = useState({});
  const [attendanceLogs, setAttendanceLogs] = useState(null);
  const [absentMembers, setAbsentMembers] = useState(null);
  const [missingCheckoutsInfo, setMissingCheckoutsInfo] = useState(null);

  const loadShortHightlightsData = async () => {
    const data = (await getShortHighlights()) ?? {};
    setShortHighlightsInfo(data);
  };

  const loadAttendanceData = async () => {
    const { logs = [], absentMembers = [] } = (await getAttendanceLogs()) ?? {};
    setAttendanceLogs(logs);
    setAbsentMembers(absentMembers);
  };

  const loadMissingCheckoutsInfo = async () => {
    const info = await getMissingCheckoutsOverview();
    setMissingCheckoutsInfo(info);
  };

  const handleLogUpdate = (log) => {
    const newLogs = attendanceLogs.map((l) => (l.id === log.id ? log : l));
    setAttendanceLogs(newLogs);
  };

  const handleLogDelete = (log) => {
    const remainingLogs = attendanceLogs?.filter((l) => l.id !== log.id);
    setAttendanceLogs(remainingLogs);
    setAbsentMembers([log, ...absentMembers]);
  };

  const getShortHighlightsData = () =>
    shortHighlights.map((s) => {
      const checkIns = shortHighlightsInfo.checkIns
        ? String(shortHighlightsInfo.checkIns).padStart(2, "0")
        : shortHighlightsInfo.checkIns;

      const totalMembers = shortHighlightsInfo.totalMembers
        ? String(shortHighlightsInfo.totalMembers).padStart(2, "0")
        : shortHighlightsInfo.totalMembers;

      let infoText = "";
      if (s.key === "checkIns") infoText = `out of ${totalMembers} members`;
      else if (s.key === "checkOuts") infoText = `out of ${checkIns} check ins`;
      else if (s.key === "remoteCheckIns") infoText = `out of ${checkIns} check ins`;
      else if (s.key === "totalMembers") infoText = `members at Slack workspace`;

      return { ...s, infoText };
    });

  useEffect(() => {
    loadShortHightlightsData();
    // loadMissingCheckoutsInfo();
    // loadListOfLateCheckIns();
    loadAttendanceData();
  }, []);

  const renderShortHighlights = () => (
    <>
      <div className="row mt-4">
        {getShortHighlightsData().map((s, idx) => (
          <div key={idx} className="col-xl-3 col-lg-4 mb-4">
            <ShortHighlightsCard
              title={s.title}
              value={shortHighlightsInfo[s.key]}
              icon={s.icon}
              infoText={s.infoText}
            />
          </div>
        ))}

        {/* <div className="col-xl-3 col-lg-4 mb-4">
          <LateCheckInHighlight info={missingCheckoutsInfo} />
        </div> */}
      </div>
    </>
  );

  const renderPageContent = () => (
    <div className="container-fluid mb-5">
      {renderShortHighlights()}
      <div className="row">
        <div className="col-xl-8">
          {<AttendanceLogTable logs={attendanceLogs} onLogUpdate={handleLogUpdate} onDelete={handleLogDelete} />}
        </div>
        <div className="col-xl-4 d-flex flex-column">
          <LateCheckInHighlight info={missingCheckoutsInfo} />
          <div className="mb-3"></div>
          <AbsentMembersList list={absentMembers} />
        </div>
      </div>
    </div>
  );

  return renderPageContent();
};

export default Dashboard;
